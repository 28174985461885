import { getCurrentInstance, onMounted, ref } from "vue";
import { getProductCollection, addProductCollection, deleteProductCollection } from '@/api/channels/store'
import {useStore} from "@/store";
import {useRoute} from "vue-router/composables";
// import { product } from "@/components/Store/WebCommerce/Product/FormProduct/state";

/**
 * @typedef {'featured' | 'bestseller' | 'newproduct' | 'special'} Section
 */

/**
 * @type {import('vue').Ref<Record<Section, { title: string, items: any[] }>>} ref
 */
const productSections = ref({
    featured: {
        title: 'Featured Product',
        items: [],
    },
    bestseller: {
        title: 'Best Seller',
        items: [],
    },
    newproduct: {
        title: 'New Arrival',
        items: [],
    },
    special: {
        title: 'Special',
        items: [],
    },
})

const loading = ref(false)
const fetched = ref(false)

/**
 *
 * @param channelId
 */
export function useProductCollection(channelId) {
    const store = useStore()
    const route = useRoute()
    const el = getCurrentInstance().proxy;
    const message = el.$message

    /**
     * Fetch data
     *
     * @param {Section[]|undefined} sections
     * @param refresh
     * @returns {Promise<void>}
     */
    const fetch = async (sections = undefined, refresh = false) => {
        if (loading.value) return

        if (fetched.value && !refresh) return

        loading.value = true

        const selectedSection = sections || Object.keys(productSections.value)

        await Promise.all(selectedSection.map(async section =>  {
            const { data: response } = await getProductCollection({
                business_id: route.query.business_id,
                user_id: store.state.user?.id,
                params: {
                    channel_id: channelId,
                    selected_type: section,
                },
            })

            productSections.value[section].items = response.data
        }))

        loading.value = false
        fetched.value = true
    }

    const remove = async (type, productId, catalogId) => {

        try {
            await deleteProductCollection({
                business_id: route.query.business_id,
                user_id: store.state.user?.id,
                product_id: productId,
                catalog_id: catalogId,
                params: {
                    selected_type: type,
                    channel_id: channelId,
                },
            })

            fetch([type], true)
        } catch (err) {
            message.error(err?.response?.data?.message || err?.response?.message || err?.message)
        }
    }

    onMounted(() => {
        fetch(undefined, true)
    })

    const catalogCollection = (catalogId) => {
        const collections = [];

        for (const collection in productSections.value) {
            if (productSections.value[collection].items.some(item => item.catalog_id === catalogId)) {
                collections.push({
                    type: collection,
                    title: productSections.value[collection].title,
                })
            }
        }

        return collections
    }

    const addToCollection = async (productId, catalogId, type) => {
        try {
            await addProductCollection({
                business_id: route.query.business_id,
                user_id: store.state.user?.id,
                params: { channel_id: channelId },
                data: {
                    selected_type: type,
                    product_id: productId,
                    catalog_id: catalogId,
                },
            })

            fetch([type], true)
        } catch (err) {
            message.error(err?.response?.data?.message || err?.response?.message || err?.message)
        }
    }

    return {
        productSections,
        fetch,
        remove,
        catalogCollection,
        addToCollection,
    }
}