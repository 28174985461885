<script setup>

import { useProductCollection } from "@/components/Store/WebCommerce/composables/productCollection";
import { useRoute } from "vue-router/composables";
import { computed } from "vue";
import { useCurrency } from "../../../../composable/useCurrency";

const route = useRoute()

const { productSections, remove } = useProductCollection(route.params.channelId)
const type = computed(() => route.params.type || 'featured')
const collection = computed(() => productSections.value[type.value])

</script>

<template>
  <div>
    <h1 class="h4 mb-5">
      {{ $t('product.collection.title') }}
    </h1>

    <div class="product-collection">
      <div class="collection-type">
        <RouterLink
          v-for="(section, key) in productSections"
          :key="key"
          :class="{
            selected: type === key
          }"
          :to="{ params: { ...$route.params, type: key }, query: $route.query }"
          class="collection-type--item"
        >
          {{ section.title }}
        </RouterLink>
      </div>
      <div class="collection-items">
        <Transition mode="out-in" name="slide-fade">
          <div :key="type">
            <a-empty v-if="!collection.items.length" />
            <div
              v-for="item in collection.items"
              :key="item.catalog_id"
              class="collection-items--item"
            >
              <img :src="`${item.showimg_url}?tr=w-50,h-50,cm-pad_resize,bg-FFFFFF`" class="item-iamge" />
              <div>
                <div class="product-title">
                  <RouterLink
                    :to="{
                      name: 'channel.store.product.edit',
                      params: { channelId: $route.params.channelId, id: item.product_id },
                      query: { ...$route.query }
                    }"
                  >
                    {{ item.title }}
                  </RouterLink>
                </div>
                <div>{{ useCurrency().format(item.selling_price, 'IDR') }}</div>
              </div>

              <div class="actions">
                <a-popconfirm
                  placement="bottomRight"
                  ok-text="Remove"
                  @confirm="() => remove(type, item.product_id, item.catalog_id)"
                >
                  <template #title>
                    {{ $t('product.collection.confirm.delete') }}
                  </template>

                  <a-button
                    type="danger"
                    size="small"
                    icon="delete"
                    html-type="button"
                  />
                </a-popconfirm>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h1 {
  font-size: 1.5rem;
}

.product-collection {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.collection-type {
  flex: 0 0 auto;
  width: 200px;
}

.collection-type--item {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: .25rem;
  padding: .35rem 1rem;
  margin-bottom: .25rem;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all .2s ease-in;
    opacity: .1;
    background-color: transparent;
  }

  &.selected {
    color: var(--color-accent);

    &:before {
      background-color: var(--color-accent);
    }
  }
}

.collection-items {
  flex-grow: 1;
  width: 100%;

  &--item {
    background: #fff;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;

    img {
      flex: 0 0 auto;
      width: 50px;
    }

    .product-title {
      font-weight: 500;
      margin-bottom: .25rem;
    }

    .actions {
      flex: 0 0 auto;
      margin-left: auto;

    }
  }
}

</style>