import apiClient from '@/services/axios'

export const getProductList = ({ business_id, user_id, params }) => {
  return apiClient({
    method: 'get',
    url: 'channel/store/product/query/',
    headers: { 
      'business-id': business_id,
      'user-id': user_id,
    },
    params,
  })
}

export const getProductBrands = ({ business_id, user_id, params }) => {
  return apiClient({
    method: 'get',
    url: 'channel/store/product/query/brands',
    headers: { 
      'business-id': business_id,
      'user-id': user_id,
    },
    params,
  })
}

export const getProductStoreCategories = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url : '/channel/store/product/query/categories',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getProductStoreCategoriesV2 = ({ business_id, user_id, params }) => {
  return apiClient({
    method: 'get',
    url : '/channel/store/product/query/v2/categories',
    headers: {
      'business-id': business_id,
      'user-id': user_id,
    },
    params,
  })
}

export const getResellerLevel = ({ business_id, user_id, params }) => {
  return apiClient({
    method: 'get',
    url : '/channel/store/reseller/query/reseller/level',
    headers: {
      'business-id': business_id,
      'user-id': user_id,
    },
    params,
  })
}

export const getProductCollection = ({ business_id, user_id, params }) => {
  return apiClient({
    method: 'get',
    url : '/channel/store/product/query/selected',
    headers: {
      'business-id': business_id,
      'user-id': user_id,
    },
    params,
  })
}

export const getResellerComission = ({ headers, params }) => {
  return apiClient({
    method: 'get',
    url: '/channel/store/price/query/reseller/commission',
    headers,
    params,
  })
}

export const getImageToken = ({ headers, params }) => {
  return apiClient({
    method: 'get',
    url: '/channel/store/data/query/token',
    headers,
    params,
  })
}

export const addProductCollection = ({ business_id, user_id, params, data }) => {
  return apiClient({
    method: 'post',
    url: '/channel/store/product/cmd/selected',
    headers: { 
      'business-id': business_id,
      'user-id': user_id,
    },
    params,
    data,
  })
}

export const saveDiscount = ({ headers, data, params }) => {
  return apiClient({
    method: 'post',
    url: 'channel/store/promotions/cmd/upsert/discount',
    headers,
    data,
    params,
  })
}

export const saveImage = ({ headers, data, params }) => {
  return apiClient({
    method: 'put',
    url: '/channel/store/product/cmd/images',
    headers,
    data,
    params,
  })
}

export const savePrice = ({ headers, data, params }) => {
  return apiClient({
    method: 'put',
    url: '/channel/store/price/cmd/',
    headers,
    data,
    params,
  })
}

export const saveCommission = ({ headers, data, params }) => {
  return apiClient({
    method: 'put',
    url: 'channel/store/price/cmd/reseller/commission',
    headers,
    data,
    params,
  })
}

export const updateProductStatus = ({ business_id, user_id, product_id, state, params }) => {
  return apiClient({
    method: 'put',
    url: `/channel/store/product/cmd/state/${product_id}/${state}`,
    headers: { 
      'business-id': business_id,
      'user-id': user_id,
    },
    params,
  })
}

export const updateDescription = ({ business_id, user_id, data, params }) => {
  return apiClient({
    method: 'put',
    url: '/channel/store/product/cmd/upsert/description',
    headers: { 
      'business-id': business_id,
      'user-id': user_id,
    },
    data,
    params,
  })
}

export const updateImageAttribute = ({ headers, data, params }) => {
  return apiClient({
    method: 'put',
    url: '/channel/store/product/cmd/image/attribute',
    headers,
    data,
    params,
  })
}

export const removeImage = ({ headers, data, params }) => {
  return apiClient({
    method: 'delete',
    url: '/channel/store/product/cmd/images',
    headers,
    data,
    params,
  })
}

export const deleteDiscount = (config) => {
  return apiClient({
    method: 'delete',
    url: '/channel/store/promotions/cmd/discount',
    headers: config.headers,
    params: config.params,
  })
}

export const deleteProductCollection = ({ business_id, user_id, product_id, catalog_id, params }) => {
  return apiClient({
    method: 'delete',
    url: `/channel/store/product/cmd/selected/${product_id}/${catalog_id}`,
    headers: { 
      'business-id': business_id,
      'user-id': user_id,
    },
    params,
  })
}