var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h1',{staticClass:"h4 mb-5"},[_vm._v(" "+_vm._s(_vm.$t('product.collection.title'))+" ")]),_c('div',{staticClass:"product-collection"},[_c('div',{staticClass:"collection-type"},_vm._l((_setup.productSections),function(section,key){return _c('RouterLink',{key:key,staticClass:"collection-type--item",class:{
          selected: _setup.type === key
        },attrs:{"to":{ params: { ..._vm.$route.params, type: key }, query: _vm.$route.query }}},[_vm._v(" "+_vm._s(section.title)+" ")])}),1),_c('div',{staticClass:"collection-items"},[_c('Transition',{attrs:{"mode":"out-in","name":"slide-fade"}},[_c('div',{key:_setup.type},[(!_setup.collection.items.length)?_c('a-empty'):_vm._e(),_vm._l((_setup.collection.items),function(item){return _c('div',{key:item.catalog_id,staticClass:"collection-items--item"},[_c('img',{staticClass:"item-iamge",attrs:{"src":`${item.showimg_url}?tr=w-50,h-50,cm-pad_resize,bg-FFFFFF`}}),_c('div',[_c('div',{staticClass:"product-title"},[_c('RouterLink',{attrs:{"to":{
                    name: 'channel.store.product.edit',
                    params: { channelId: _vm.$route.params.channelId, id: item.product_id },
                    query: { ..._vm.$route.query }
                  }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('div',[_vm._v(_vm._s(_setup.useCurrency().format(item.selling_price, 'IDR')))])]),_c('div',{staticClass:"actions"},[_c('a-popconfirm',{attrs:{"placement":"bottomRight","ok-text":"Remove"},on:{"confirm":() => _setup.remove(_setup.type, item.product_id, item.catalog_id)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('product.collection.confirm.delete'))+" ")]},proxy:true}],null,true)},[_c('a-button',{attrs:{"type":"danger","size":"small","icon":"delete","html-type":"button"}})],1)],1)])})],2)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }